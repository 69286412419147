import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { service } from 'common/service'
import toFormData from 'common/toFormData'
import { getStore } from 'common/store'
import { companyService } from './useCompany'
import { subscriptionService } from './useSubscription'

export const uboService = service
  .enhanceEndpoints({ addTagTypes: ['Ubo'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      createUbo: builder.mutation<Res['ubo'], Req['createUbo']>({
        invalidatesTags: [{ id: 'LIST', type: 'Ubo' }],
        query: (query: Req['createUbo']) => {
          return {
            body: toFormData({ ...query.data, documents: undefined }),
            method: 'POST',
            url: `partners/company/${query.company_id}/subscriptions/${query.subscription_id}/identifications`,
          }
        },
      }),
      deleteUbo: builder.mutation<Res['ubo'], Req['deleteUbo']>({
        invalidatesTags: [{ id: 'LIST', type: 'Ubo' }],
        query: (query: Req['deleteUbo']) => ({
          body: {},
          method: 'DELETE',
          url: `partners/company/${query.company_id}/subscriptions/${query.subscription_id}/identifications/${query.id}`,
        }),
        transformResponse: (res) => {
          getStore().dispatch(companyService.util.invalidateTags(['Company']))
          getStore().dispatch(
            subscriptionService.util.invalidateTags(['Subscription']),
          )
          return res
        },
      }),
      getUbos: builder.query<Res['ubos'], Req['getUbos']>({
        providesTags: (res) => [{ id: res?.id, type: 'Ubo' }],
        query: (query: Req['getUbos']) => ({
          url: `partners/company/${query.company_id}/subscriptions/${query.subscription_id}/identifications`,
        }),
        transformResponse: (res) => {
          return res
        },
      }),
      updateUbo: builder.mutation<Res['ubo'], Req['updateUbo']>({
        invalidatesTags: (res) => [
          { id: 'LIST', type: 'Ubo' },
          { id: res?.id, type: 'Ubo' },
        ],
        query: (query: Req['updateUbo']) => ({
          body: toFormData({ ...query.data, documents: undefined }),
          method: 'PUT',
          url: `partners/company/${query.company_id}/subscriptions/${query.subscription_id}/identifications/${query.data.id}`,
        }),
        transformResponse: (res) => {
          getStore().dispatch(companyService.util.invalidateTags(['Company']))
          getStore().dispatch(
            subscriptionService.util.invalidateTags(['Subscription']),
          )
          return res
        },
      }),
      // END OF ENDPOINTS
    }),
  })

export async function createUbo(
  store: any,
  data: Req['createUbo'],
  options?: Parameters<typeof uboService.endpoints.createUbo.initiate>[1],
) {
  return store.dispatch(uboService.endpoints.createUbo.initiate(data, options))
}
export async function deleteUbo(
  store: any,
  data: Req['deleteUbo'],
  options?: Parameters<typeof uboService.endpoints.deleteUbo.initiate>[1],
) {
  return store.dispatch(uboService.endpoints.deleteUbo.initiate(data, options))
}
export async function getUbos(
  store: any,
  data: Req['getUbos'],
  options?: Parameters<typeof uboService.endpoints.getUbos.initiate>[1],
) {
  return store.dispatch(uboService.endpoints.getUbos.initiate(data, options))
}
export async function updateUbo(
  store: any,
  data: Req['updateUbo'],
  options?: Parameters<typeof uboService.endpoints.updateUbo.initiate>[1],
) {
  return store.dispatch(uboService.endpoints.updateUbo.initiate(data, options))
}
// END OF FUNCTION_EXPORTS

export const {
  useCreateUboMutation,
  useDeleteUboMutation,
  useGetUbosQuery,
  useUpdateUboMutation,
  // END OF EXPORTS
} = uboService

/* Usage examples:
const { data, isLoading } = usegetUbosQuery({ id: 2 }, {}) //get hook
const [createUbo, { isLoading, data, isSuccess }] = useCreateUboMutation() //create hook
uboService.endpoints.getUbos.select({id: 2})(store.getState()) //access data from any function
*/
